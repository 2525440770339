import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import {
  MoreVert,
  Edit,
  Delete,
  PeopleAlt,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import { apiLink } from "../common";
import axios from "axios";
function ActionMenuButton({
  handleEdit,
  handleDelete,
  fileName,
  emailAccount,
  phoneNum,
  onClose,
  onOpen,
  genericId,
  folder_id,
}) {
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState(fileName);
  const [email, setEmail] = useState(emailAccount);
  const [phone, setPhone] = useState(phoneNum);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFoldersArray, setSelectedFoldersArray] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState("");
  // const [getAssignInvestor, setGetAssignInvestor] = useState();
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getFolders = async () => {
      if (!user) return;
      try {
        // if (showLoading) setLoadingFolders(true);
        const response = await axios.get(apiLink + "/api/folders", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            searchText: "", // Use debouncedSearchText in the request
          },
        });

        // setLoadingFolders(false);
        await setFolders(response.data);
        // if (user?.role !== "A")
        //   setCurrentFolder(response.data[0].id, response.data[0].name);
      } catch (error) {
        // if (error.response.status == 401) handleLogout();
        // setLoadingFolders(false);
        setError(error.message);
      }
    };
    getFolders();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenEditDialog(true);
    handleClose();
  };
  const handleUserClick = () => {
    setOpenUserDialog(true);
    handleClose();
    handleIdWiseAssignInvestor();
  };

  const handleDeleteClick = () => {
    setConfirmDelete(true);
    handleClose();
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    onClose();
  };
  const handleUserDialogClose = () => {
    setOpenUserDialog(false);
    onClose();
  };

  const handleDeleteConfirm = () => {
    setConfirmDelete(false);
    handleDelete();
    onClose();
  };

  const handleDeleteCancel = () => {
    setConfirmDelete(false);
    onClose();
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const handleFolderChange = (event, newValue) => {
    const idsArray = newValue.map((folder) => folder.clientId);
    setSelectedFoldersArray(Array.isArray(newValue) ? newValue : []);
    const idsString = idsArray.join(",");
    setSelectedFolders(idsString);
  };
  const handleIdWiseAssignInvestor = async () => {
    if (!user) return;
    try {
      // if (showLoading) setLoadingFolders(true);
      const response = await axios.get(
        apiLink + "/api/folders/assign-user/" + folder_id,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            searchText: "", // Use debouncedSearchText in the request
          },
        }
      );
// console.log(folders[0].clientId,"folderssssssssss");
// console.log(folders,"folderssssssssss");


      const result = await response?.data[0]?.clientId?.split(",");
      console.log(result,"resultresult");
      
      const initialSelectedFolders = await folders?.filter((folder) =>
        result.includes(String(folder?.clientId))
      );
console.log(initialSelectedFolders,"initialSelectedFolders");

      setSelectedFoldersArray(initialSelectedFolders);

      // setArrayValue(result);
      // await setGetAssignInvestor(response?.data?.clientId);
      // if (user?.role !== "A")
      //   setCurrentFolder(response.data[0].id, response.data[0].name);
      // setLoadingFolders(false);
    } catch (error) {
      // if (error.response.status == 401) handleLogout();
      // setLoadingFolders(false);
      setError(error.message);
    }
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose();
          onClose();
        }}
      >
        {folder_id !== undefined && (
          <MenuItem onClick={handleUserClick}>
            <ListItemIcon>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary="User" />
          </MenuItem>
        )}
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>Edit </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="standard"
            style={{ marginTop: 8 }}
            label={"Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          />
          {emailAccount && (
            <TextField
              fullWidth
              variant="standard"
              style={{ marginTop: 8 }}
              label={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          {emailAccount && (
            <TextField
              fullWidth
              variant="standard"
              style={{ marginTop: 8 }}
              label={"Phone"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button
            style={{ color: "white" }}
            onClick={() => {
              handleEdit(name, email, phone);
              handleEditDialogClose();
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* ------ assign user ------ */}
      <Dialog
        open={openUserDialog}
        onClose={handleUserDialogClose}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>Assign User </DialogTitle>
        <DialogContent>
          {/* <TextField
            fullWidth
            variant="standard"
            style={{ marginTop: 8 }}
            label={"Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          /> */}
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={folders}
            disableCloseOnSelect
            defaultValue={[selectedFoldersArray]}
            value={selectedFoldersArray}
            onChange={handleFolderChange}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              );
            }}
            style={{ marginTop: "10px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign Investor"
                placeholder="Select Investor"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserDialogClose}>Cancel</Button>
          <Button
            style={{ color: "white" }}
            onClick={() => {
              genericId(folder_id, selectedFolders);

              handleUserDialogClose();
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={confirmDelete} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete <b>{fileName}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteConfirm}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ActionMenuButton;
