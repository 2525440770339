import React, { useState } from 'react';
// import {auth,sendPasswordResetEmail} from '../firebase';
// import firebase from '../firebase';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // await firebase.auth().sendPasswordResetEmail(email);
      // sendPasswordResetEmail(auth,email);
      alert('Reset email sent, please also check junk/spam folder.');
      navigate('/login');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth='sm'>
      <Typography variant='h4' component='h1' align='center' gutterBottom>
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin='normal'
          variant='standard'
          disabled={isLoading}
        />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          fullWidth
          disabled={isLoading}
          style={{ marginTop: '1rem', color: 'white' }}
        >
          {isLoading ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Send reset email'
          )}
        </Button>
        {error && <p>{error}</p>}
      </form>
    </Container>
  );
}

export default ResetPassword;
