import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Toolbar, Typography, AppBar, Grid } from "@mui/material";
import logo from "../images/drewby_logo.png";

import { UserContext } from "../context/UserContext";

function Navbar() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    setUser(null);
    navigate("/login");
  };

  useEffect(() => {
    let userObj = localStorage.getItem("user");
    if (userObj && !user) {
      userObj = JSON.parse(userObj);
      setUser(userObj);
    } else {
      navigate("/login");
    }
  }, [setUser]);

  return (
    <AppBar position="sticky" color="transparent" elevation={0}>
      <Toolbar>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ backgroundColor: "white" }}
        >
          {user ? (
            <Grid item md={2}>
              <Typography
                className="hello_hide"
                variant="body1"
                sx={{ marginRight: "8px" }}
              >
                Hello, {user.name}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs="auto" className="logo_center">
            <img style={{ width: 115 }} src={logo} />
          </Grid>
          <Grid
            container
            item
            md={2}
            justifyContent={"end"}
            className="logout_container"
          >
            {user ? (
              <>
                <Button
                  onClick={handleLogout}
                  variant="outlined"
                  className="logout_button"
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                {/* <Button component={Link} to='/register' variant='contained' className="logout_button">
                  Register
                </Button> */}
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
