import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import Login from "./components/Login";
// import Register from './components/Register';
import Dashboard from "./components/Dashboard";
import Folder from "./components/Folder";
import Navbar from "./components/Navbar";
import NotFound from "./components/NotFound";
import ResetPassword from "./components/Reset";
import { UserProvider } from "./context/UserContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Register from "./components/Register";
const theme = createTheme({
  palette: {
    primary: {
      main: "#4caf50", // Change this value to the success color of your choice
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router>
          <Navbar />
          <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path='/register' element={<Register />} /> */}
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/folder/:folderId" element={<Folder />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
