import React, { useState, useContext } from "react";
import axios from "axios";
import firebase from "../firebase";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { apiLink } from "../common";
import VerificationDialog from "./VerificationDialog";

import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [phone, setPhone] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleVerify = async (code) => {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const idToken = await userCredential.user.getIdToken();
      const response = await axios.post(apiLink + "/api/users/verify", {
        idToken,
        code,
      });

      const { user, token } = response.data;
      user.token = token;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      setUser(user);
      navigate("/");
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message);
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const idToken = await userCredential.user.getIdToken();

      const response = await axios.post(apiLink + "/api/users/login", {
        idToken,
      });

      if (response.data.phone) {
        setPhone(response.data.phone);
        setDialogOpen(true);
      } else {
        const { user, token } = response.data;
        console.log(response.data);
        user.token = token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        setUser(user);
        navigate("/");
      }
    } catch (error) {
      if (error?.response?.message) {
        alert(error?.response?.message);
        setError(error?.response?.message);
      } else {
        if (error?.response?.message) setError(error?.response?.message);
        else setError(error?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   try {
  //     const userCredential = await firebase
  //       .auth()
  //       .signInWithEmailAndPassword(email, password);
  //     const idToken = await userCredential.user.getIdToken();

  //     const response = await axios.post(apiLink + '/api/users/login', {
  //       idToken,
  //     });

  //     const { user, token } = response.data;
  //     user.token = token;
  //     localStorage.setItem('user', JSON.stringify(user));
  //     localStorage.setItem('token', token);
  //     setUser(user);
  //     navigate('/');
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  // console.log(user,"user");

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Investor Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="standard"
          disabled={isLoading}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="standard"
          disabled={isLoading}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
          style={{ marginTop: "1rem", color: "white" }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Login"}
        </Button>
        {error && <p>{error}</p>}
      </form>

      <Grid style={{ marginTop: 16 }} container justifyContent={"center"}>
        <Link to="/reset-password">Forgot Password?</Link>
      </Grid>
      <VerificationDialog
        open={isDialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        phone={phone}
        handleVerify={handleVerify}
      />
    </Container>
  );
}

export default Login;
