import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

function VerificationDialog({ open, handleClose, phone, handleVerify }) {
  const [code, setCode] = useState('');
  const [verifying, setVerifying] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setVerifying(true);
    await handleVerify(code);
    setVerifying(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container justifyContent={'space-between'}>
          Enter Verification Code
          <Button
            onClick={() => {
              handleClose();
              setCode('');
            }}
            startIcon={<Clear />}
            color='error'
          >
            Close
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          A verification code has been sent to the phone number ending in{' '}
          {phone}.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            style={{ marginTop: 16 }}
            label='Verification Code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            autoFocus
            variant='standard'
          />
          {verifying ? (
            <CircularProgress style={{ marginTop: 22 }} />
          ) : (
            <Button
              style={{ marginTop: 22 }}
              type='submit'
              variant='contained'
              color='success'
            >
              verify
            </Button>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default VerificationDialog;