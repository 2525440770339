import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Typography, List, ListItem, CircularProgress } from '@mui/material';
import UploadFile from './UploadFile';
import { UserContext } from '../context/UserContext';
import { apiLink } from '../common';
function Folder() {
  const { folderId } = useParams();
  const location = useLocation();
  const [folder, setFolder] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchFolder = async () => {
      try {
        if (!user) return;

        const currentFolderId =
          folderId || extractFolderIdFromUrl(location.pathname);

        if (!currentFolderId) {
          setError('Invalid folder ID');
          return;
        }
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
          },
        };
        const response = await axios.get(
          `${apiLink}/api/folders/${currentFolderId}`,
          config
        );
        setFolder(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchFolder();
  }, [folderId, location.pathname, user]);

  if (error) return <p>{error}</p>;

  if (!folder) {
    return <CircularProgress />;
  }

  console.log('folder');

  return (
    <div>
      <Typography variant='h3'>{folder.name}</Typography>
      <Typography variant='h4'>Investor: {folder.Client.name}</Typography>

      {/* Display files here */}
      <div>
        <Typography variant='h5'>Files:</Typography>
        {folder.files.length > 0 ? (
          <List>
            {folder.files.map((file, index) => (
              <ListItem key={index}>{file.name}</ListItem>
            ))}
          </List>
        ) : (
          <Typography variant='body1'>No files in this folder.</Typography>
        )}
      </div>

      {/* Upload file */}
      <UploadFile folderId={folder.id} />
    </div>
  );
}

export default Folder;

function extractFolderIdFromUrl(pathname) {
  const match = pathname.match(/\/folder\/(\d+)/);
  return match ? match[1] : null;
}
