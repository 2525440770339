
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDZ1oAEuKX3ZNZWZHhpBianoh7WOXUCGB8',
  authDomain: 'drewby-app.firebaseapp.com',
  projectId: 'drewby-app',
  storageBucket: 'drewby-app.appspot.com',
  messagingSenderId: '113713772262',
  appId: '1:113713772262:web:fda8297723480c98d1ed27',
  measurementId: 'G-M8NF0QP7KN',
};

firebase.initializeApp(firebaseConfig);

export default firebase;