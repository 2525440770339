import React, { useState, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { apiLink } from '../common';

function UploadFile({ folderId }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFiles(e.dataTransfer.files);
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]); // Ensure 'files' matches the field name in multer config
    }

    try {
      const response = await axios.post(
        `${apiLink}/api/files/upload/${folderId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Handle successful upload
      console.log('Files uploaded successfully!', response.data);
      setSelectedFiles([]); // Clear selected files after upload
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '16px',
        width: '300px',
        margin: 'auto',
        textAlign: 'center',
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
      />
      <label htmlFor="fileInput">
        <Button variant="contained" component="span">
          Select Files
        </Button>
      </label>
      <Typography variant="body2" sx={{ margin: '16px 0' }}>
        Drag and drop files here or click the button to select files.
      </Typography>
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={selectedFiles.length === 0}
      >
        Upload
      </Button>
    </Box>
  );
}

export default UploadFile;
