import React, { useState, useContext } from 'react';
import axios from 'axios';
import firebase from '../firebase';
import { auth, createUserWithEmailAndPassword } from '../firebase';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { apiLink } from '../common';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("register")
    if (name.trim() === '') {
      setError('Name is required');
      return;
    }

    try {
   
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
        console.log(userCredential,"userCredentialuserCredential");
        
      const idToken = await userCredential.user.getIdToken();
      // console.log(idToken,"idToken");

      // const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // console.log(userCredential, "userCredential");
      
      // const idToken = await userCredential.user.getIdToken();
      // console.log(idToken, "idToken");
      
      // const userCredential = await firebase
      // .auth()
      // .createUserWithEmailAndPassword(email, password);
    // const idToken = await userCredential.user.getIdToken();
    const response = await axios.post(apiLink + '/api/users/register', {
      idToken,
      name,
      email,
      isAdmin,
      phone
      
    });
      localStorage.setItem('user', JSON.stringify(response.data));
      setUser(response.data);
      navigate('/');
    } catch (error) {
      setError(error.message);
      console.log(error);
      
    }
  };

  return (
    <Container maxWidth='sm'>
      <Typography variant='h4' component='h1' align='center' gutterBottom>
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Name'
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          margin='normal'
        />
        <TextField
          label='Phone Number'
          type='number'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          required
          margin='normal'
        />
        <TextField
          label='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin='normal'
        />
        <TextField
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin='normal'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
          }
          label='Register as admin'
        />
        <Button type='submit' variant='contained' color='primary' fullWidth>
          Register
        </Button>
        {error && <p>{error}</p>}
      </form>
    </Container>
  );
}

export default Register;
